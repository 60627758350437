<template>
  <div class="mint">
    <div class="bg_gradient">
      <section class="section_detail">
        <div class="container">
          <div class="row gx-3 gx-lg-5 align-items-center">
            <div class="col-lg-6">
              <div class="home_shell float">
                <img
                  src="img/shell/1.png"
                  class="shell01"
                  alt=""
                >
                <img
                  src="img/shell/2.png"
                  class="shell02 wow fadeIn infinite"
                  data-wow-duration="7s"
                  alt=""
                >
                <img
                  src="img/shell/3.png"
                  class="shell03 wow flash infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/4.png"
                  class="shell04 wow fadeIn infinite"
                  data-wow-duration="5s"
                  alt=""
                >
                <img
                  src="img/shell/5.png"
                  class="shell05"
                  alt=""
                >
                <img
                  src="img/shell/6.png"
                  class="shell06 wow fadeIn infinite"
                  data-wow-duration="1s"
                  alt=""
                >
                <img
                  src="img/shell/7.png"
                  class="shell07"
                  alt=""
                >
                <img
                  src="img/shell/8.png"
                  class="shell08 wow flash infinite"
                  data-wow-duration="6s"
                  alt=""
                >
              </div>
            </div>
            <div class="col-lg-6">
              <div class="detail_info my-3">
                <h1>A New Age in Metaverse: Manifesto of Poseidon</h1>
                <div class="des">
                  <p>
                    We have prepared 1000 NFTs full of magic and creativity for
                    the first wave. <br>
                    Please look forward to the moment when the pearl clam shell
                    is opened.
                  </p>
                </div>
              </div>
              <p>
                Remaining <span class="text_default">{{ Remaining }}</span>/1000 NFT
              </p>
              <div class="mint_control">
                <div class="mint_number">
                  <a
                    href="#"
                    @click.prevent="minusMint"
                  ><i class="fa-solid fa-minus" /></a>
                  <input
                    v-model="mintNum"
                    class="form-control"
                    type="text"
                  >
                  <a
                    href="#"
                    @click.prevent="addMint"
                  ><i class="fa-solid fa-plus" /></a>
                </div>
                <div class="price">
                  {{ mintNum * 0.08 }}
                </div>
                <button
                  class="btn btn-primary btn_mint"
                  :disabled="btnMintDisabled"
                  @click="asynchandleMint"
                >
                  MINT
                </button>
              </div>
              <div class="mt-3">
                <small>Special reminder: After pressing the mint key, it will take
                  about 10 to 60 seconds before jumping to the wallet
                  confirmation page.</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- footer -->
      <footer class="footer">
        <div class="container text-center">
          <div>Atlantis -P © 2022</div>
        </div>
        <img
          src="img/footer.png"
          class="footer_img"
          alt=""
        >
      </footer>
      <!-- Connect Modal -->
      <div
        id="connectModal"
        class="modal fade"
        tabindex="-1"
        aria-labelledby="connectModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="connectModalLabel"
                class="modal-title"
              >
                Connect
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body">
              <ul class="wallet-list">
                <li>
                  <img
                    src="img/metamask.png"
                    alt=""
                  >
                  Meta Mask
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import contractJson from '../../hardhat/deployments/localhost/AtlantisNFT.json'
import contractJson from '../assets/contract/AtlantisNFT.json'
import creator from '../assets/contract/creator-infos.json'
import signature from '../assets/contract/signatures.json'
import { ethers } from 'ethers'
export default {
  name: 'Mint',
  data: () => {
    return {
      mintNum: 1,
      btnMintDisabled: false,
      Remaining: 'Connect to MetaMask',
      startTime: '2022-08-19T20:00:00+08:00',
    }
  },
  async mounted() {
    const contralBtnTime =
      new Date(this.startTime).getTime() - new Date().getTime()

    if (contralBtnTime > 0) {
      this.btnMintDisabled = true
      setTimeout(() => {
        this.btnMintDisabled = false
      }, contralBtnTime)
    }

    const provider = window.ethereum
    if (typeof provider !== 'undefined') {
      const myaccount = await provider.request({
        method: 'eth_accounts',
      })
      if (myaccount.length === 1) {
        this.Remaining = await this.supply()
      }
    }
  },
  methods: {
    supply: async () => {
      const provider = window.ethereum
      if (typeof provider !== 'undefined') {
        const myaccount = await provider.request({
          method: 'eth_accounts',
        })
        if (myaccount.length === 1) {
          const Web3Provider = new ethers.providers.Web3Provider(provider)
          const signer = Web3Provider.getSigner()
          const aContract = new ethers.Contract(
            contractJson.address,
            contractJson.abi,
            signer
          )
          const totalSupply = await aContract.totalSupply()

          const mintIndex = parseInt(totalSupply.toString())
          return 1000 - mintIndex
        }
      } else {
        console.log('MetaMask is not installed!')
      }
    },
    addMint() {
      if (this.mintNum != 5) {
        this.mintNum += 1
      }
    },
    minusMint() {
      if (this.mintNum != 1) {
        this.mintNum -= 1
      }
    },
    async Mint(aContract, { creatorInfos, signatures, count }) {
      try {
        return await aContract.mint(this.mintNum, creatorInfos, signatures, {
          value: `${(count[1] * this.mintNum).toString()}`,
        })
      } catch (e) {
        if (e.error.code === -32000) {
          this.$toast.error(`err: insufficient funds for gas * price`)
        } else {
          this.$toast.error(`${e.error.message}`)
        }
      }
    },
    async translate(tx) {
      try {
        const receipt = await tx.wait()
        this.$toast.success('blockHash: ' + receipt.blockHash)
      } catch (e) {
        this.$toast.error(e.message)
        // this.$toast.error(e.error.message)
      }
    },

    async asynchandleMint() {
      const contralBtnTime =
        new Date(this.startTime).getTime() - new Date().getTime()

      if (contralBtnTime < 0) {
        this.btnMintDisabled = true
        const provider = window.ethereum
        if (typeof provider !== 'undefined') {
          const myaccount = await provider.request({
            method: 'eth_requestAccounts',
          })

          this.$emit('handleCallBack', myaccount[0].slice(0, 6) + '...')
          this.Remaining = await this.supply()
          const Web3Provider = new ethers.providers.Web3Provider(provider)
          const signer = Web3Provider.getSigner()
          const aContract = new ethers.Contract(
            contractJson.address,
            contractJson.abi,
            signer
          )
          const count = await aContract.saleInfo()
          const totalSupply = await aContract.totalSupply()
          // const signature = whitelist[myaccount[0]]

          const mintIndex = parseInt(totalSupply.toString())
          const creatorInfos = creator.slice(
            mintIndex,
            mintIndex + this.mintNum
          )
          const signatures = signature.slice(
            mintIndex,
            mintIndex + this.mintNum
          )
          const sec = (Math.random() * 50 + 10).toFixed(0)
          // 
          this.$toast.warning(`Waiting for transaction ${sec} sec`, {
            timeout: sec * 1000,
          })
          await new Promise((resolve) => setTimeout(resolve, sec * 1000))

          const tx = await this.Mint(aContract, {
            creatorInfos,
            signatures,
            count,
          })
          this.btnMintDisabled = false
          if (tx !== undefined) {
            this.translate(tx)
          }
        } else {
          this.btnMintDisabled = false
          this.$toast.warning('MetaMask is not installed!')
        }
      } else {
        this.$toast.warning('The sale is not started yet!')
      }
    },
  },
}
</script>
